import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ResetStyles } from './Components/Styles/ResetStyles';
import NotFound from './Pages/NotFound/NotFound';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Login from './Pages/Login/Login';
import Menu from './Pages/Menu/Menu';
import Protocolo from './Pages/Protocolo/Protocolo';
import Consultar from './Pages/Consultar/Consultar';
import Dispositivos from './Pages/Dispositivos/Dispositivos';
import Contato from './Pages/Contato/Contato';
import Resumo from './Pages/Resumo/Resumo';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';

export default function App() {
  return (
    <>
      <ResetStyles />
      <Navbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFound />} />
        <Route
          path="/menu"
          element={
            <ProtectedRoute>
              <Menu />
            </ProtectedRoute>
          }
        />
        <Route
          path="/consultar"
          element={
            <ProtectedRoute>
              <Consultar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/protocolo"
          element={
            <ProtectedRoute>
              <Protocolo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dispositivos"
          element={
            <ProtectedRoute>
              <Dispositivos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contato"
          element={
            <ProtectedRoute>
              <Contato />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resumo"
          element={
            <ProtectedRoute>
              <Resumo />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </>
  );
}
//funciona